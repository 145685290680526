import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { mediaPath } from '@app/helpers/media.helper';

@Pipe({
  name: 'mediaPath'
})
export class MediaPathPipe implements PipeTransform {
  transform(value?: string | null): any {
    return mediaPath(value);
  }
}

@NgModule({
  declarations: [MediaPathPipe],
  exports: [MediaPathPipe]
})
export class MediaPathPipeModule {}
